import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../components/button';
import {WillAnimate} from '../../components/content_animate';
import {Cta} from '../../components/cta';
import {Layout} from '../../components/layout';
import {NumberHero} from '../../components/number_hero';
import {ScrollTo} from '../../components/scroll_to';

export default ({data}: any) => (
    <Layout
        title="Onze aanpak bij het ontwikkelen van softwareproducten"
        description="Ons softwareproductontwikkelingsbureau werkt inten­sief samen met opdracht­gevers in een flex­ibel en iteratief proces. Zo ontwikkelen we samen prachtige softwareproducten."
        ogImage="werkwijze.png"
        translatedPagePath="/en/approach/">
        <WillAnimate as="div" name="hero" id="hero" className="hero hero--page">
            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h2>
                        Bij Label305 werken we inten&shy;sief samen met onze opdracht&shy;gever in een{' '}
                        <span className="highlight">flexibel</span> en <span className="highlight">iteratief</span>{' '}
                        proces. We werken vanuit een <span className="highlight">product&shy;visie</span> en dekken{' '}
                        risico's goed af. Zo ontwikkelen we samen kwalitatieve software&shy;producten.
                    </h2>
                </div>
            </div>

            <NumberHero location="left" />

            <ScrollTo className="hero__scroll" target="/nl/werkwijze/#from-hero" position="start" />
        </WillAnimate>

        <section className="section">
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Iteratief <br /> gezamen&shy;lijk <br /> proces
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Voor ons is software&shy;product&shy;ontwikkeling een proces van visie&shy;vorming tot{' '}
                            capability transfer. Bij het ontwikkelen van een nieuw software&shy;product doorlopen we{' '}
                            gezamenlijk het proces van A tot Z.
                        </p>
                        <p>
                            Tijdens de ontwikkeling is een belangrijke taak voor de opdracht&shy;gever
                            weggelegd&mdash;het vervullen van de rol van{' '}
                            <span className="highlight">product&shy;eigenaar</span>. De product&shy;eigenaar is
                            intensief betrokken bij het ontwikkel&shy;proces om zo altijd de juiste focus te waarborgen.
                        </p>
                        <p>
                            Het product is en blijft van onze opdracht&shy;gever. Wij dragen het{' '}
                            <span className="highlight">intellectueel eigendom</span> automatisch over.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <div className="workflow-summary">
                <ul className="workflow-summary__items">
                    <li className="summary-item">
                        <ScrollTo target="/nl/werkwijze/#item-1">1. Kickoff</ScrollTo>
                    </li>
                    <li className="summary-item">
                        <ScrollTo target="/nl/werkwijze/#item-2">2. Prototype</ScrollTo>
                    </li>
                    <li className="summary-item summary-item--split-up">
                        <ScrollTo target="/nl/werkwijze/#item-3">3. Productrealisatie</ScrollTo>
                    </li>
                    <li className="summary-item summary-item--split-back">
                        <ul>
                            <li>
                                <ScrollTo target="/nl/werkwijze/#item-4a">4a. Software serveren</ScrollTo>
                            </li>
                            <li>
                                <ScrollTo target="/nl/werkwijze/#item-4b">4b. Doorontwikkelen</ScrollTo>
                            </li>
                        </ul>
                    </li>
                    <li className="summary-item">
                        <ScrollTo target="/nl/werkwijze/#item-5">5. Ontwikkelingstransitie</ScrollTo>
                    </li>
                </ul>
            </div>

            <article className="workflow">
                <div className="container">
                    <WillAnimate as="div" className="workflow-item workflow-item--start" id="item-1" />

                    <WillAnimate as="div" className="workflow-item">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">1</div>
                            <h2>Neem ons mee in de productvisie</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Het proces begint met het ont&shy;dekken van wat we willen creëren. Tijdens de{' '}
                                kick-off-sessie bepalen we samen de rand&shy;voorwaarden voor het software&shy;product,{' '}
                                op basis van de <span className="highlight">product&shy;visie</span>.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Samen om tafel</li>
                                <li className="ion-md-arrow-dropright">&#189; &ndash; 2 dagen</li>
                                <li className="ion-md-arrow-dropright">1 &ndash; 3 specialisten</li>
                            </ul>
                        </div>

                        <div className="workflow-item__buttons">
                            <Button
                                url="/nl/werkwijze/kick-off/"
                                name="Meer over de kick-off"
                                title="Aanpakstap 1. Kick-off"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item" id="item-2">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">2</div>
                            <h2>Concept- en techniekvalidatie</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                De realisatie van een nieuw software&shy;product staat of valt meestal met het{' '}
                                <span className="highlight">valideren</span> van een concept of techniek. Daarom starten{' '}
                                we eerst met het maken van een <span className="highlight">prototype</span>.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Iteraties van 1 week</li>
                                <li className="ion-md-arrow-dropright">2 &ndash; 6 weken</li>
                                <li className="ion-md-arrow-dropright">1 &ndash; 3 specialisten</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/nl/werkwijze/prototyping/"
                                name="Meer over het prototype"
                                title="Aanpakstap 2. Prototype"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item" id="item-3">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">3</div>
                            <h2>Product&shy;realisatie</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Voor de product&shy;realisatie gaan we verder met een schone lei. Met de kennis en{' '}
                                plannen uit de kick-off- en concept&shy;fases in handen, ont&shy;werpen en{' '}
                                ont&shy;wikkelen we samen de{' '}
                                <span className="highlight">eerste levens&shy;vatbare versie</span> van het
                                software&shy;product&mdash;helemaal vanuit het niets in een relatief korte periode.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Iteraties van 1 &ndash; 2 weken</li>
                                <li className="ion-md-arrow-dropright">Tussentijdse mijlpalen</li>
                                <li className="ion-md-arrow-dropright">4 &ndash; 12 maanden</li>
                                <li className="ion-md-arrow-dropright">2 &ndash; 4 specialisten</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/nl/werkwijze/productrealisatie/"
                                name="Meer over realisatie"
                                title="Aanpakstap 3. Realisatie"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item workflow-item--left" id="item-4a">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">4a</div>
                            <h2>Software betrouwbaar aanbieden in productie</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Voor een gelanceerd software&shy;product is het essen&shy;tieel een{' '}
                                <span className="highlight">continue, veilige en juiste werking</span> te{' '}
                                waar&shy;borgen&mdash;dit gebeurt niet vanzelf. Daarom stemmen we samen af welk service-
                                en onderhouds&shy;niveau het beste past bij het product.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Doorlopend onderhoud</li>
                                <li className="ion-md-arrow-dropright">Proactief ingrijpen</li>
                                <li className="ion-md-arrow-dropright">Continu monitoren</li>
                                <li className="ion-md-arrow-dropright">Uptime gegarandeerd</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/nl/werkwijze/service/"
                                name="Meer over service"
                                title="Aanpakstap 4A. Serveren"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item workflow-item--right" id="item-4b">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">4b</div>
                            <h2>Continu verbeteren en doorontwikkelen</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Een software&shy;product is nooit af. Momentum en betrokkenheid moeten we vasthouden, en{' '}
                                stagnatie vermijden. Daarom maken we afspraken over{' '}
                                <span className="highlight">periodieke door&shy;ontwikkeling</span>.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Iteraties van 1 &ndash; 2 weken</li>
                                <li className="ion-md-arrow-dropright">Vanaf 1 iteratie per maand</li>
                                <li className="ion-md-arrow-dropright">1 &ndash; 4 specialisten</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/nl/werkwijze/continue-doorontwikkeling/"
                                name="Meer over doorontwikkelen"
                                title="Aanpakstap 4B. Doorontwikkelen"
                            />
                        </div>
                    </WillAnimate>

                    <WillAnimate as="div" className="workflow-item workflow-item--last" id="item-5">
                        <div className="workflow-item__line" />
                        <div className="workflow-item__title">
                            <div className="title__number">5</div>
                            <h2>Van product naar een zelf&shy;voorzienend product&shy;bedrijf</h2>
                        </div>
                        <div className="workflow-item__copy">
                            <p className="p-lead">
                                Producten die wij ont&shy;wikkelen moeten onze dienst&shy;verlening kunnen{' '}
                                <span className="highlight">ont&shy;groeien</span>. Daarom helpen we met het opzetten{' '}
                                van een intern product&shy;ontwikkelings&shy;team bij de opdracht&shy;gever.
                            </p>
                            <ul className="list">
                                <li className="ion-md-arrow-dropright">Helemaal optioneel</li>
                                <li className="ion-md-arrow-dropright">Capability transfer</li>
                                <li className="ion-md-arrow-dropright">Minimaal 3 kandidaten</li>
                                <li className="ion-md-arrow-dropright">Vanaf 3 maanden per kandidaat</li>
                            </ul>
                        </div>
                        <div className="workflow-item__buttons">
                            <Button
                                url="/nl/werkwijze/capability-transfer/"
                                name="Meer over ontwikkelingstransitie"
                                title="Aanpakstap 5. Transitie"
                            />
                        </div>
                    </WillAnimate>
                </div>
            </article>

            <WillAnimate as="article" className="content">
                <div className="image image--text-left">
                    <div className="image__info">
                        <h2>
                            Het is <span className="highlight">essen&shy;tieel</span> om{' '}
                            <span className="highlight">continu</span> in&shy;zicht te hebben in de visie en de{' '}
                            plan&shy;nen voor het software&shy;product. We werken veel samen en bouwen duur&shy;zame
                            relaties op.
                        </h2>
                    </div>
                    <div className="image__content">
                        <Img alt="Desks" fluid={data.autosociaal.childImageSharp.fluid} />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            We zitten regelmatig met de producteigenaar van onze opdrachtgever om tafel om de backlog te{' '}
                            verfijnen, en nieuwe plannen te maken.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        autosociaal: file(relativePath: {eq: "office/autosociaal.jpg"}) {
            ...fluid1200Width
        }
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
